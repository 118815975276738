import React from 'react'
import { withPrefix } from 'gatsby'

import * as timelineStyles from './timeline.module.css'

const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
      return index === 0 ? letter.toLowerCase() : letter.toUpperCase()
    })
    .replace(/\s+/g, '')
    .replace(/-+/g, '')
}

const hasImage = (imagePath, imageClass, altText) => {
  if (imagePath) {
    const image = withPrefix(imagePath)
    const classifier = camelize(imageClass)
    return (
      <img className={timelineStyles[classifier]} src={image} alt={altText} />
    )
  }
  return
}

const Timeline = (props) => (
  <section className={timelineStyles.container}>
    <figure className={timelineStyles.trunk} />
    {props.waypoints.map((waypoint, i) => (
      <div
        key={i}
        className={
          timelineStyles.leaf +
          (i % 2 === 0 ? ' ' + timelineStyles.right : ' ' + timelineStyles.left)
        }
      >
        <div className={timelineStyles.block}>
          <time>{waypoint.date}</time>
          <h3>{waypoint.title}</h3>
          {hasImage(waypoint.image, waypoint.imageClass, waypoint.title)}
          <div dangerouslySetInnerHTML={{ __html: waypoint.content }} />
        </div>
      </div>
    ))}
  </section>
)

export default Timeline
