import React from 'react'

import Layout from '../components/layout'
import Footer from '../components/footer'
import Timeline from '../components/timeline'

const waypoints = [
  {
    title: 'Released Colorian',
    date: '2021',
    content:
      '<p>Color palette creator app for Mac. Open source <a href="https://github.com/laitine/colorian">available</a>.</p>',
    image: '/timeline/colorian-app.png',
    imageClass: 'colorian-img',
  },
  {
    title: 'NNg Master UX Certificate',
    date: '2020',
    content:
      '<p>Achieved <a href="https://www.nngroup.com/ux-certification">Master certification</a> with specialties in UX Management and Interaction Design. #3 from Finland to be listed.</p>',
    image: '/timeline/nng-uxc-master-badge.png',
    imageClass: 'nng-m-img',
  },
  {
    title: 'Veikkaus Design Impact',
    date: '2019',
    content:
      '<p>Bringing the customer to focus and defining a responsible UX strategy for sustainable business.</p>',
    image: '/timeline/veikkaus-logo.png',
    imageClass: 'veikkaus-img',
  },
  {
    title: 'SOK Omnichannel Experience',
    date: '2018',
    content:
      '<p>Building a platform for developing omnichannel experiences for the biggest retailer in Finland</p>',
    image: '/timeline/sok-logo.png',
    imageClass: 'sok-img',
  },
  {
    title: 'NNg UX Certificate',
    date: '2018',
    content:
      '<p>Received <a href="https://www.nngroup.com/ux-certification">certification</a> with specialty in Interaction Design</p>',
    image: '/timeline/nng-uxc-badge.png',
    imageClass: 'nng-img',
  },
  {
    title: 'Aalto University Design Project',
    date: '2018',
    content:
      '<p>Mentoring students in <a href="https://aaltofestival.fi/2018/en/design-project-2018-gala/">sustainable urban planning project</a> that would innovate logistics</a></p>',
    image: '/timeline/aalto-logo.png',
    imageClass: 'aalto-dp2-img',
  },
  {
    title: 'Posti Design Systems',
    date: '2017',
    content:
      '<p>Organized and developed the first Design Systems for all brands of the Finnish Postal Office</p>',
    image: '/timeline/posti-design-system.png',
    imageClass: 'posti-ds-img',
  },
  {
    title: 'Aalto University Design Project',
    date: '2017',
    content:
      '<p>Mentoring students in UCD methods to design a new business with the <a href="https://www.nitor.com/fi/uutiset-ja-blogi/mentoroinnilla-tutuksi-tulevaisuuden-tekijoiden-kanssa">team of designers</a></p>',
    image: '/timeline/aalto-logo.png',
    imageClass: 'aalto-dp1-img',
  },
  {
    title: 'Finnair Digital Experience',
    date: '2016',
    content:
      "<p>Renewal of the digital brand and rethinking the service through digital channels. First person in the company's history to do user testing at the airport.</p>",
    image: '/timeline/finnair-emblem.png',
    imageClass: 'finnair-img',
  },
  {
    title: 'PRIO: Post delivery planning ',
    date: '2015',
    content:
      '<p>Designed and developed a post delivery route planning experience that promotes collaboration and reduces complexity for humans and the systems behind it</p>',
    image: '/timeline/posti-prio.png',
    imageClass: 'prio-img',
  },
  {
    title: 'Post Electronic Delivery Book',
    date: '2015',
    content:
      '<p>Prototyped a mobile app that helps post delivery every day</p>',
    image: '/timeline/posti-edb.png',
    imageClass: 'posti-edb-img',
  },
  {
    title: 'Willow UI',
    date: '2015',
    content:
      '<p>Visualized <a href="https://github.com/NitorCreations/willow-ui/">cloud computing capacity</a> in real-time</p>',
    image: '/timeline/willow-ui.png',
    imageClass: 'willow-ui-img',
  },
  {
    title: 'Released DWS-1',
    date: '2014',
    content:
      '<p>Published an <a href="https://github.com/laitine/dws-1">open source synthesizer</a> that works in the browser</p>',
    image: '/timeline/rad-dws1.png',
    imageClass: 'rad-dws1-img',
  },
  {
    title: 'Microsoft Mobile B2B Experience',
    date: '2014',
    content:
      '<p>Collaborated on branding a new look for Microsoft web apps and automated prototyping for B2B services</p>',
    image: '/timeline/msft-logo.png',
    imageClass: 'msft-img',
  },
  {
    title: 'Nitor',
    date: '2014',
    content:
      '<p>First Designer at Nitor. My formal entrance to the world of consulting.</p>',
    image: '/timeline/nitor-logo.png',
    imageClass: 'nitor-img',
  },
  {
    title: 'GisGes',
    date: '2013',
    content:
      '<p>I was the CTO of a startup setting up the tech infrastructure and Lean UX practices</p>',
    image: '/timeline/gisges-logo.png',
    imageClass: 'gisges-img',
  },
  {
    title: 'Grip Studios Interactive',
    date: '2012',
    content:
      '<p>Worked on CMSs with responsive web design and data visualizations. Got into the world of topic maps and contributed open source to <a href="http://wandora.org">Wandora.</a></p>',
    image: '/timeline/wandora-logo.png',
    imageClass: 'grip-img',
  },
  {
    title: 'IT Student of The Year',
    date: '2012',
    content:
      '<p>I was <a href="https://www.tieto.fi/uutiset/tieto-palkitsi-niko-laitisen-vuoden-it-opiskelijana">awarded</a> with the national title</p>',
    image: '/timeline/prize.png',
    imageClass: 'student-award-img',
  },
  {
    title: 'Digitizing Helsinki City Library',
    date: '2011',
    content:
      "<p>Brought the digital presence of the metropolitan library to a new era. We kicked off the first mobile apps, open source projects and open APIs in the library's history.</p>",
    image: '/timeline/public-libraries-logo.png',
    imageClass: 'pub-lib-img',
  },
  {
    title: 'First startup job at Pore',
    date: '2010',
    content:
      '<p>Ideated fresh concepts for mobile music making and created a webstore for Cymbidium Records on the side. Developed an interactive media experience based on a company patent.</p>',
    image: '/timeline/pore-logo.png',
    imageClass: 'pore-img',
  },
  {
    title: 'Started at University of Helsinki',
    date: '2007',
    content:
      '<p>Major in Computer Science and Minor studies in Cognitive Science, Mathematics, Statistics, Philosophy and Musicology. Got inducted to the Uschool of usability.</p>',
    image: '/timeline/university-of-helsinki-logo.png',
    imageClass: 'uni-img',
  },
]

const TimelinePage = () => (
  <Layout>
    <article>
      <h3 className="centered narrowed-heading">
        Selected works, mentions and kudos through out the years.
      </h3>
      <Timeline waypoints={waypoints} />
    </article>
    <Footer />
  </Layout>
)

export default TimelinePage
