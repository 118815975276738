// extracted by mini-css-extract-plugin
export var container = "timeline-module--container--1ANSZ";
export var trunk = "timeline-module--trunk--OeJKl";
export var leaf = "timeline-module--leaf--rln-X";
export var left = "timeline-module--left--qs-sM";
export var right = "timeline-module--right--JQQ+V";
export var block = "timeline-module--block--L7Kmi";
export var colorianImg = "timeline-module--colorian-img--7YBPu";
export var nngMImg = "timeline-module--nng-m-img--Fg+3M";
export var veikkausImg = "timeline-module--veikkaus-img--85Bm9";
export var sokImg = "timeline-module--sok-img--Ak3pP";
export var nngImg = "timeline-module--nng-img--hdJc9";
export var aaltoDp2Img = "timeline-module--aalto-dp2-img--gC-zu";
export var postiDsImg = "timeline-module--posti-ds-img--hCdSg";
export var aaltoDp1Img = "timeline-module--aalto-dp1-img--u+y-4";
export var finnairImg = "timeline-module--finnair-img--bW6EY";
export var prioImg = "timeline-module--prio-img--7UFdu";
export var postiEdbImg = "timeline-module--posti-edb-img--5RhPT";
export var willowUiImg = "timeline-module--willow-ui-img--hqR0C";
export var radDws1Img = "timeline-module--rad-dws1-img--ZUyQH";
export var msftImg = "timeline-module--msft-img--T7gGn";
export var nitorImg = "timeline-module--nitor-img--HWT06";
export var gisgesImg = "timeline-module--gisges-img--QHJ-D";
export var gripImg = "timeline-module--grip-img--+iLAE";
export var studentAwardImg = "timeline-module--student-award-img--ya1V0";
export var pubLibImg = "timeline-module--pub-lib-img--QenrA";
export var poreImg = "timeline-module--pore-img--KtR1G";
export var uniImg = "timeline-module--uni-img--29Zpv";